export enum Entities {
  buildings = "list of building",
  building_manager = "building manager",
  core_manager = "core manager",
  distro_manager = "distro manager",
  distro_dhcp_manager = "distro(DHCP) manager",
  access_manager = "access switch manager",
  user_management = "user list",
  action_analytics = "action analytics",
}

export type UserListDataType = {
  [email: string]: string[];
};
